.spp-engraving-preview {
  .engraving-size {
    &-30ml {
      &.engraving-form-choice {
        &-1,
        &-13 {
          .background-prod-image {
            background-image: none;
            @include breakpoint($medium-up) {
              background-image: url('/media/export/cms/engraving/product/926x541/jo_100ml_926x541_l.jpg') !important; // Overriding the image from base file
            }
            .new-canvas {
              .background-prod-image-inner {
                background-image: url('/media/export/cms/engraving/product/478x426/jo_100ml_478x426_l.jpg') !important; // Overriding the image from base file
                .engrave-placement {
                  top: 8em;
                  right: 64px;
                  @include breakpoint($medium-up) {
                    top: 27.5em;
                    right: 26.5em;
                  }
                }
              }
            }
          }
          &.engraving-21490 {
            .engrave-placement {
              top: 28em;
            }
          }
        }
        &-2 {
          &.engraving-21490 {
            .engrave-placement {
              #{$ldirection}: 0;
              @include breakpoint($medium-up) {
                #{$ldirection}: 43em;
              }
            }
          }
          .engrave-placement {
            #{$ldirection}: 34.1em;
          }
        }
      }
    }
    &-50ml {
      .engraving-form-choice {
        &-1 {
          &.active-engraving-form {
            .engrave-placement {
              #{$rdirection}: 4em;
              #{$ldirection}: 0;
              @include breakpoint($medium-up) {
                #{$ldirection}: 40.5em;
              }
            }
          }
        }
        &-5 {
          &.active-engraving-form {
            .engrave-placement {
              #{$ldirection}: 0;
              @include breakpoint($medium-up) {
                #{$ldirection}: 39em;
              }
            }
          }
        }
      }
    }
    &-100ml {
      &.background-swap {
        &.engraving-form-choice-1 {
          .engrave-placement {
            #{$ldirection}: 5px;
            @include breakpoint($medium-up) {
              #{$ldirection}: 39.7em;
            }
          }
        }
        &.engraving-form-choice-5 {
          .engrave-placement {
            input {
              top: 194px;
              margin-#{$ldirection}: 62px;
              font-size: 14px;
              width: 60%;
              @include breakpoint($landscape-up) {
                top: 70px;
                width: 100%;
                margin-#{$ldirection}: 10px;
              }
            }
          }
        }
      }
    }
    &-200g {
      .engraving-form-choice {
        &-3 {
          &.active-engraving-form {
            .engrave-placement {
              #{$rdirection}: 0;
              @include breakpoint($medium-up) {
                #{$rdirection}: 4em;
              }
            }
          }
        }
      }
    }
  }
}

.overlay-container {
  .cart-engraving-preview {
    .engraving-size-100ml {
      .engrave-placement {
        .engraving {
          &-script {
            #{$ldirection}: 53px;
            top: 110px;
            font-size: 18px;
            width: 60%;
            @include breakpoint($medium-up) {
              #{$ldirection}: 15px;
              top: 35px;
              width: 80%;
            }
          }
          &-block {
            #{$ldirection}: 53px;
            top: 110px;
            font-size: 18px;
            width: 60%;
            @include breakpoint($medium-up) {
              #{$ldirection}: 15px;
              top: 35px;
              width: 80%;
            }
          }
        }
      }
    }
    .engraving-size-30ml {
      .engrave-placement {
        #preview-2-1 {
          &.engraving-script,
          &-shadow.engraving-script {
            font-size: 2.5em;
            height: 120px;
            @include breakpoint($medium-up) {
              height: 50px;
              #{$ldirection}: -14px;
            }
          }
          &.engraving-block,
          &-shadow.engraving-block {
            font-size: 2.5em;
            height: 120px;
            @include breakpoint($medium-up) {
              font-size: 2.5em;
              height: 50px;
              #{$ldirection}: -14px;
            }
          }
        }
      }
    }
  }
}

.adpl {
  .engraving-form {
    &-element {
      input[type='text'] {
        width: 50%;
      }
    }
  }
}
