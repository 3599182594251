#confirmation-panel {
  div.print-buttons {
    display: block;
  }
}
#confirm {
  .return-link-container {
    .button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}