.sitewide-footer-formatter {
  &__trustmark {
    display: none;
  }
  &__trustmark-wrapper {
    &.trustmark-logo-wrapper {
      clear: both;
      display: block;
      margin: 0;
      padding: 20px 0;
      text-align: center;
      @include breakpoint($landscape-up) {
        @include swap_direction(margin, 25px auto 0 10px);
        order: 4;
        padding: 0;
        width: 59%;
      }
    }
    img {
      margin: 0;
    }
  }
  .footer-country-link {
    @include breakpoint($landscape-up) {
      width: auto;
    }
  }
  &__terms-links {
    &.footer-links {
      margin: 0;
    }
  }
  .footer-language-toggle {
    .active {
      text-decoration: underline;
    }
  }
  & &__content {
    @include breakpoint($landscape-up) {
      flex: 0 61%;
    }
  }
  & &__top {
    @include breakpoint($landscape-up) {
      flex: 0 39%;
    }
  }
  .site-footer-email-signup {
    &__terms-checkbox {
      input[type='checkbox'] ~ .label {
        padding: 0 0 5px;
        &::before {
          display: none;
        }
      }
    }
  }
}
