.lp-window-root {
  .lp_survey_area {
    .lp_pages_area {
      .lp_radiobutton_wrapper,
      .lp_checkbox_wrapper {
        .lp_radio_button,
        .lp_checkbox_button {
          label {
            padding-#{$ldirection}: 20px;
          }
          input[type="radio"],
          input[type="checkbox"] {
            position: absolute;
          }
        }
      }
    }
  }
}
