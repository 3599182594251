#viewcart {
  .viewcart-panel {
    .engraving-edit {
      .engraving-selectors-container {
        input {
          display: none;
        }
        label {
          margin-inline: 17px;
        }
      }
    }
  }
}
