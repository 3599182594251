@font-face {
  font-family: 'Noto Serif JP';
  src:
    local('Noto Serif JP Regular'), local('Noto-Serif-JP-Regular'),
    url('/_fonts/jomalone2/cjk/Noto_Serif_JP/NotoSerifJP-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Noto Sans JP';
  src:
    local('Noto Sans JP Regular'), local('Noto-Sans-JP-Regular'),
    url('/_fonts/jomalone2/cjk/Noto_Sans_JP/NotoSansJP-Regular.woff2') format('woff2');
  font-display: swap;
  font-style: normal;
  font-weight: normal;
}
